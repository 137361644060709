const enabledRoutes = [
  'Define Procedure',
  'Import Data',
  'Train a model',
  'Validation',
];

export default function(router) {
  const currentPath = getCurrentPath(router);

  const userRoutesConfig = getUserRoutesConfig(router);

  const routes = getRoutes(userRoutesConfig, currentPath);

  return routes;
}

function getCurrentPath(router) {
  return router.currentRoute._value.fullPath.split('/').pop();
}

function getUserRoutesConfig(router) {
  const { children } = router.options.routes.find((r) => r.path === '/user');
  return children;
}

function getRoutes(userRoutesConfig, currentPath) {
  let prevRouter = null;
  let nextRoute = null;

  userRoutesConfig
    .filter((el) => enabledRoutes.includes(el.name))
    .forEach((route, index, arr) => {
      if (route.path === currentPath) {
        prevRouter = arr[index - 1] ? arr[index - 1] : null;
        nextRoute = arr[index + 1] ? arr[index + 1] : null;
      }
    });
  return { prevRouter, nextRoute };
}
