import { StatisticalLoggerInterface } from './StatisticalLoggerInterface';
import datetime from 'lodash';
import { default as dt } from 'py-datetime';
import { Process } from './Process';

export class StatisticalLogger extends StatisticalLoggerInterface {
  constructor(
    callbackAfterCycleEnd = null,
    callbackAfterCycleStart = null,
    callBackToUpdateCurrentCycleTimes = null,
    callBackOnInferenceStateChanged = null,
    objectToCallForProcessingTelemetry = null,
    callBackForCurrentCycleTime = null,
    callBackForBackgroundTime = null,
    kwargs
  ) {
    super();
    this.callBackOnInferenceStateChanged = callBackOnInferenceStateChanged;
    this.callBackToUpdateCurrentCycleTimes = callBackToUpdateCurrentCycleTimes;
    this.objectToCallForProcessingTelemetry = objectToCallForProcessingTelemetry;
    this.callbackAfterCycleEnd = callbackAfterCycleEnd;
    this.callbackAfterCycleStart = callbackAfterCycleStart;
    this.callBackForCurrentCycleTime = callBackForCurrentCycleTime;
    this.callBackForBackgroundTime = callBackForBackgroundTime;
    this.currentProcess = null;
    this.startCycleDetectorCheck = false;
    this.endCycleDetectorCheck = false;
    this.cycleCount = 0;
    this.bgTime = 0;
  }

  sendPredToProcess = ({ pred, time, fps }) => {
    this.count += 1;
    this.newPredReceived(pred, time, fps);
  };

  // todo add file ending logic here

  inferenceStateChanged = kwargs => {
    if (kwargs['state']) {
      this.setupVars(kwargs);
      if (this.callBackOnInferenceStateChanged) {
        this.callBackOnInferenceStateChanged(this.taskInfo);
      }
    }
  };

  getCurrentStepsStatus = prediction => {
    let currentProcess = this.getCurrentProcess(prediction);
    if (currentProcess !== null) {
      return currentProcess.completedSteps.includes(prediction);
    }
    return false;
  };

  newPredReceived = (predictions, time, fps) => {
    this.currentTime = time;
    let timeDiff = 1000 / fps / 1000;
    let identifiedProcesses = [];
    if (this.endAllProcessesIfTimeDiffMoreThanN(timeDiff, time)) {
      timeDiff = 0;
    }

    for (const prediction of predictions) {
      this.currentProcess = this.getCurrentProcess(prediction);
      if (this.currentProcess === null || this.currentProcess === undefined) {
        continue;
      }
      if (!identifiedProcesses.includes(this.currentProcess.id)) {
        identifiedProcesses.push(this.currentProcess.id);
      }
      if (this.currentProcess.cycleStart) {
        this.currentProcess.endFrame = this.count;
        this.currentProcess.addSubStepTime(timeDiff, prediction);
        this.currentProcess.markStepAsCompleted(prediction);
        let cycleData = this.tryToEndCycle(prediction, timeDiff, time);
        this.tryToSaveCycleData(cycleData);

        if (this.callBackToUpdateCurrentCycleTimes) {
          this.callBackToUpdateCurrentCycleTimes(
            timeDiff,
            prediction,
            this.taskInfo.processes,
            this.currentProcess
          );
        }
      } else {
        this.tryToStartCycle(prediction, time);
      }
    }
    this.addTotalCycleTimes(timeDiff, identifiedProcesses);
    this.previousTime = this.currentTime;
  };

  endAllProcessesIfTimeDiffMoreThanN = (timeDiff, time) => {
    if (Math.abs(timeDiff) >= 3) {
      this.forceEndAllProcesses(time);
      return true;
    }
    return false;
  };

  forceEndAllProcesses = (time = datetime.now()) => {
    if (this.taskInfo) {
      for (let process in this.taskInfo.processes) {
        this.currentProcess = process;
        if (this.currentProcess.cycleStart) {
          this.currentProcess.reasonForEnd.add('force_end_all_processes');
          const data = this.endCycle(time);
          this.tryToSaveCycleData(data);
        }
      }
    }
  };

  tryToSaveCycleData = data => {
    if (
      data &&
      data['total_cycle_time'] > this.currentProcess.minimumCycleTime
    ) {
      this.saveCycleData(data);
    }
  };

  saveCycleData = data => {
    this.cumulatedProcesses[data['process_id']].push(data);
  };

  addTotalCycleTimes = (timeDiff, identifiedProcess) => {
    for (let i in identifiedProcess) {
      if (this.taskInfo.processes[i].cycleStart) {
        this.taskInfo.processes[i].totalCycleTime += timeDiff;
        if (this.callBackForCurrentCycleTime) {
          this.callBackForCurrentCycleTime({
            [i]: this.taskInfo.processes[i].totalCycleTime.toFixed(2)
          });
        }
        if (this.callBackForBackgroundTime) {
          if (
            Object.entries(this.taskInfo.processes[i].subStepsTimes).slice(-1)
          ) {
            let newList = Object.entries(
              this.taskInfo.processes[i].subStepsTimes
            ).slice(-1);
            this.bgTime = newList[0][1].toFixed(2);
            this.callBackForBackgroundTime(this.bgTime);
          }
        }
      }
    }
  };

  tryToStartCycle = (prediction, time) => {
    if (this.currentProcess.canRestartCycleOn === null) {
      return;
    }
    if (this.currentProcess.canRestartCycleOn.includes(prediction)) {
      this.currentProcess.resetValues();
      this.currentProcess.cycleStart = true;
      this.currentProcess.cycleIdentifier = dt
        .datetime(time)
        .str('%Y-%m-%d %H:%M:%S');
      this.currentProcess.startFrame = this.count;
      if (this.callbackAfterCycleStart) {
        if (Object.keys(this.taskInfo.processes).length > 1) {
          if (
            this.currentProcess.id ==
            Object.keys(this.taskInfo.processes).slice(1)[0]
          ) {
            this.cycleCount += 1;
          }
          if (this.cycleCount == 0) {
            this.cycleCount += 1;
          }
        } else {
          this.cycleCount += 1;
        }
        // if (
        //   this.currentProcess.id ==
        //   Object.keys(this.taskInfo.processes).slice(-1)[0]
        // ) {
        //   this.cycleCount += 1;
        // }
        this.callbackAfterCycleStart(this.currentProcess, this.cycleCount);
      }
      return true;
    }
    return false;
  };

  tryToEndCycle = (prediction, timeDiff, time) => {
    if (
      this.currentProcess.canEndNormally() ||
      this.currentProcess.canEndAbnormally(prediction)
    ) {
      return this.endCycle(time);
    }
    return null;
  };

  endCycle = time => {
    this.currentProcess.cycleEndIdentifier = dt
      .datetime(time)
      .str('%Y-%m-%d %H:%M:%S');
    // let cycleData = this.currentProcess.getMissedSteps();
    let cycleData = JSON.parse(
      JSON.stringify(this.currentProcess.getMissedSteps())
    );
    if (this.callbackAfterCycleEnd) {
      this.callbackAfterCycleEnd(this.currentProcess, this.taskInfo.processes);
    }
    this.currentProcess.resetValues();
    return cycleData;
  };
}
