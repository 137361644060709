<template>
  <a-modal
    centered
    v-model:visible="showVideoModal"
    :title="videoToPreview?.name || 'Title'"
    :footer="null"
    id="valiadtetb-upload-video-preview-modal"
  >
    <video
      class="w-100"
      controls
      :src="videoToPreview?.fileURL"
      id="valiadtetb-uploaded-video"
    ></video>
  </a-modal>
  <a-row>
    <a-col
      :xs="24"
      :sm="24"
      :md="12"
      :lg="12"
      :xl="12"
      style="height: 30vh !important; margin: 0 auto"
    >
      <a-upload-dragger
        :fileList="[]"
        name="file"
        multiple
        :beforeUpload="(f) => false"
        @change="handleAddFile"
        :class="{
          'disable-click': isUploading,
        }"
        accept=".mkv,.mp4"
        id="validatetb-upload-video-dragger"
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">Click or drag file to this area to upload</p>
      </a-upload-dragger>
    </a-col>
  </a-row>
  <a-row>
    <a-col
      :xs="24"
      :sm="24"
      :md="12"
      :lg="12"
      :xl="12"
      class="d-flex flex-column mx-auto"
    >
      <div class="list-container">
        <a-list
          item-layout="horizontal"
          :data-source="fileList"
          size="small"
          rowKey="uid"
          id="validatetb-upload-video-files-list"
        >
          <template #renderItem="{ item, index }">
            <a-list-item>
              <a-list-item-meta
                :title="item.name"
                :description="getFileSize(item.size)"
                class="d-flex align-items-center"
                :id="'validatetb-upload-video-size' + index"
              >
                <template #avatar class="d-flex">
                  <video :src="item.fileURL" width="50" class="mt-1"></video>
                </template>
              </a-list-item-meta>
              <template #actions>
                <a-tooltip title="Preview file">
                  <eye-outlined
                    class="text-primary clickable"
                    :class="{
                      'disable-click': isDisabled,
                    }"
                    @click="handlePreview(item)"
                    :id="'validatetb-upload-video-preview' + index"
                  />
                </a-tooltip>
                <a-tooltip title="Remove file">
                  <delete-outlined
                    :class="{
                      'disable-click': isDisabled,
                    }"
                    class="text-danger clickable"
                    @click="handleRemove(item)"
                    :id="'validatetb-upload-video-delete' + index"
                  />
                </a-tooltip>
              </template>
            </a-list-item>
          </template>
        </a-list>
      </div>
      <br />
      <a-button
        type="primary"
        class="mx-auto"
        :loading="isUploading"
        :disabled="isDisabled"
        @click="uploadVideos"
        id="validatetb-upload-videos-btn"
        >{{ isUploading ? 'Uploading' : 'Upload' }}</a-button
      >
      <br />
      <p v-if="isUploading">{{ `Uploading file ${uploadingFileName}` }}</p>
      <a-progress :percent="progress" v-if="isUploading" />
    </a-col>
  </a-row>
</template>
<script>
import {
  InboxOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue';
import httpClient from '../../../../service/httpClient';

export default {
  props: ['taskId'],
  emits: ['changeTask', 'handleUploadedFiles', 'startLoading'],
  inject: ['toast'],
  components: {
    InboxOutlined,
    DeleteOutlined,
    EyeOutlined,
  },
  data() {
    return {
      fileUrlList: [],
      fileList: [],
      progress: 0,
      uploadingFileName: '',
      isUploading: false,
      videoToPreview: null,
      showVideoModal: false,
    };
  },
  computed: {
    isDisabled() {
      return this.fileList.length === 0 || this.isUploading;
    },
  },
  methods: {
    isFileExist(file) {
      return this.fileList
        .map((e) => e.name + e.size)
        .includes(file.name + file.size);
    },
    handleAddFile({ file }) {
      if (this.isFileExist(file)) {
        this.toast.info(`${file.name} already exists!`, { timeout: 2000 });
        return;
      }
      if (file.name.includes('.mp4') || file.name.includes('.mkv')) {
        file['fileURL'] = URL.createObjectURL(file);
        this.fileList = [...this.fileList, file];
      }
    },
    getFileSize(sizeInBytes) {
      return (sizeInBytes / 1024 / 1024).toFixed(2) + ' MB';
    },
    handlePreview(file) {
      this.videoToPreview = file;
      this.showVideoModal = true;
    },
    handleRemove(file) {
      this.fileList = this.fileList.filter((f) => f.uid !== file.uid);
    },

    async uploadVideos() {
      for (const file in this.fileList) {
        const name = `file${file + 1}`;
        let formData = this.createFormData(this.fileList[file], name);
        await this.upload(this.fileList[file].name, formData);
      }
      this.$emit('handleUploadedFiles', this.fileUrlList);
      this.fileUrlList = [];
    },

    createFormData(file, name) {
      let formData = new FormData();
      formData.append('task_id', this.taskId);
      formData.append('Validation', 'True');
      formData.append(name, file);
      return formData;
    },

    async upload(fileName, data) {
      if (!this.taskId) {
        this.toast.warning('Please first select the task');
        return;
      }
      this.isUploading = true;
      this.uploadingFileName = fileName;

      const res = await httpClient.upload(
        'organization/task/upload/',
        data,
        this
      );
      this.progress = 0;
      this.isUploading = false;
      if (res === 'error') {
        this.toast.error('Error occured while upload the file.');
        return;
      }
      this.fileUrlList = this.fileUrlList.concat(
        res.data['Successfully uploaded']
      );
      this.files = [];
    },
  },
};
</script>
<style scoped>
.list-container {
  max-height: 35vh;
  overflow-y: auto;
}
</style>
