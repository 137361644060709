<template>
  <a-row :gutter="[8, 8]" class="h-100">
    <a-col span="10">
      <a-card
        :title="selectedFileName"
        size="small"
        hoverable
        class="h-100 d-flex flex-column"
        :body-style="{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          height: '1px',
          flex: '1 1 auto',
        }"
      >
        <template #extra>
          <a-button
            id="inference-md-visualize-btn"
            :disabled="isfetchingVideoUrl || isPredictionFileNotExist"
            type="primary"
            size="small"
            class="mr-2"
            @click="$emit('handleVisualization', video)"
          >
            Visualize
            <template #icon>
              <AlignLeftOutlined />
            </template>
          </a-button>
          <a-button
            id="inference-md-csv-download-btn"
            :disabled="isfetchingVideoUrl || isPredictionFileNotExist"
            type="primary"
            size="small"
            @click="$emit('handleDownloadCSV', video)"
          >
            Download CSV
            <template #icon>
              <DownloadOutlined />
            </template>
          </a-button>
          <!-- </a-space> -->
        </template>

        <a-spin v-if="isfetchingVideoUrl" size="large" class="m-auto" />
        <div
          v-else-if="isDownloadingPrediction"
          class="m-auto d-flex flex-column"
        >
          <span class="mb-2">Downloading Prediction</span>
          <a-progress
            class="m-auto"
            type="circle"
            :percent="predictionDownloadingProgress"
            :width="80"
          />
        </div>

        <video
          v-else-if="isVideoExist"
          id="inference-md-video"
          ref="video"
          controls
          class="w-100 rounded"
          height="450"
          :src="videoUrl"
          @play="onPlay"
          @ended="onEnd"
          @pause="onPause"
          @loadeddata="onLoad"
        />
        <div v-else class="w-100 d-flex" style="height: 40vh !important">
          <a-result title="Video Not Found!" class="m-auto">
            <template #icon>
              <exclamation-circle-outlined class="text-danger" />
            </template>
          </a-result>
        </div>

        <div
          v-if="negativeStepsList.length > 0"
          class="py-1 w-100 mt-1  h-100 d-flex flex-column"
          style="border-top: 1px solid lightgrey;"
        >
          <h6>Negative step(s) Performed</h6>
          <div
            id="inference-md-neg-steps"
            style="height: 1px; overflow-y: auto; flex: 1 1 auto;"
          >
            <a-tag
              v-for="(negativeStep, index) in negativeStepsList"
              :id="'inference-neg-step' + index"
              :key="index"
              class="my-1"
              color="error"
            >
              {{ stepsTranslationMapping[negativeStep.name] }}
            </a-tag>
          </div>
        </div>
      </a-card>
    </a-col>

    <a-col span="8">
      <steps-list
        :steps-list="stepsList"
        :current-step-list="currentStepList"
        :cycle="currentCycle"
        :cycle-time="cycleTime"
        :background-time="backgroundTimeNew"
        :detected-steps="detectedSteps"
        :negative-steps-list="negativeSteps"
        :optional-steps-list="optionalSteps"
        :steps-time="stepsTime"
        :is-task-details-loading="isFetchingTaskDetails"
        :is-prediction-file-not-exist="isPredictionFileNotExist"
        :steps-translation-mapping="stepsTranslationMapping"
        :process-details="processDetails"
        :is-validation="true"
      />
    </a-col>

    <a-col span="6">
      <missed-steps
        :missed-steps="missedSteps"
        :is-task-details-loading="isFetchingTaskDetails"
        :steps-translation-mapping="stepsTranslationMapping"
      />
    </a-col>
  </a-row>
</template>
<script>
import axios from 'axios';
import StepsList from './components/StepsList.vue';
import MissedSteps from './components/MissedStep.vue';
import { mapGetters } from 'vuex';
import {
  DownloadOutlined,
  AlignLeftOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import { StatisticalLogger } from 'src/service/telemetry/core/StatisticalLogger';

export default {
  components: {
    StepsList,
    MissedSteps,
    DownloadOutlined,
    AlignLeftOutlined,
    ExclamationCircleOutlined,
  },
  props: [
    'stepsList',
    'video',
    'isVideoExist',
    'isfetchingVideoUrl',
    'selectedFileName',
  ],
  emits: ['handleVisualization', 'handleDownloadCSV'],
  data() {
    return {
      frame: 0,
      interval: null,
      currentStepList: [],
      currentCycle: 0,
      videoUrl: '',
      filePreds: null,
      predictions: {},
      debugPrediction: { 0: '' },
      stepsTime: {},
      framesToShowMissedStepsOnList: [],
      substepList: [],
      cycleTime: { 0: '0.00', 1: '0.00' },
      backgroundTimeNew: 0,
      firstFrame: null,
      backgroundTime: 0,
      missedStepsList: [],
      missedSteps: [],
      indexOfCurrentMissedStep: 0,
      negativeStepsList: [],
      detectedSteps: {},
      isPredictionFileNotExist: false,
      isVideoPlaying: false,
      statisticalLogger: null,
      currentDate: null,
      timeMultiplier: 0,
      processDetails: {},
      missedStepsBuffer: [],
      isCycleStarted: false,
      isDownloadingPrediction: false,
      predictionDownloadingProgress: 0,
      prevTime: 0,
    };
  },

  computed: {
    ...mapGetters([
      'translationLanguage',
      'taskDetails',
      'translationDetails',
      'indexToStepsMapping',
      'taskStepsCount',
      'isMultiProcessTask',
      'processToStepListMap',
      'taskName',
      'similarSteps',
      'taskJson',
      'negativeSteps',
      'organization',
      'taskStepsCount',
      'optionalSteps',
      'stepsTranslationMapping',
      'isFetchingTaskDetails',
    ]),
    languageOptions() {
      return [
        { value: 'English', label: 'ENGLISH' },
        {
          value: `${this.translationLanguage}`,
          label: `${this.translationLanguage?.toUpperCase()}`,
        },
      ];
    },
    taskProcesses() {
      return this.$store.getters.taskProcesses;
    },
  },
  watch: {
    frame(currentFrame) {
      // console.log({ 'watch-Current': currentFrame });
      this.currentStepList = this.predictions[currentFrame] || [];
      const negativeStepIndexes = this.currentStepList?.filter((value) =>
        this.negativeSteps?.includes(value)
      );

      this.negativeStepsList = negativeStepIndexes.map(
        (index) => this.substepList[index]
      );
      // this.updateCycle();
      // this.updateTime();
      this.testing();
    },

    video(value) {
      if (value) {
        this.updatePredictions();
        this.videoUrl = this.video.video_url;
      } else {
        this.resetState();
      }
    },

    stepsList(list) {
      this.substepList = list.filter((el) => el.type === 'sub-step');
    },

    currentStepList(list) {
      list.forEach((el) => {
        const temp = { ...this.detectedSteps };
        const tempCycle = { ...temp[this.currentCycle] };
        tempCycle[el] = true;
        temp[this.currentCycle] = tempCycle;
        this.detectedSteps = temp;
      });
    },

    currentCycle(_) {
      this.detectedSteps = {};
      this.stepsTime = {};
      this.backgroundTime = 0;
      this.prevTime = 0;
    },
  },

  mounted() {
    this.substepList = this.stepsList.filter((el) => el.type === 'sub-step');
    if (this.video) {
      this.updatePredictions();
      this.videoUrl = this.video.video_url;
    }
  },

  beforeUnmount() {
    this.resetState();
  },

  methods: {
    testing() {
      // console.log('Frame', this.frame);
      const stepsObj = this.filePreds[this.frame];
      // var kwargs = {
      //   pred: stepsObj.pred,
      //   time: this.currentDate,
      //   fps: this?.video?.fps || 30
      // };
      if (stepsObj) {
        // console.log(this.debugPrediction);
        // if (this.debugPrediction[0] !== ""){
        //   kwargs["pred"] = this.debugPrediction[0] ? this.debugPrediction[0] : stepsObj.pred;
        // } else{
        //   kwargs["pred"] = stepsObj.pred;
        // }
        const kwargs = {
          pred: stepsObj.pred,
          time: this.currentDate,
          fps: this?.video?.fps || 30,
        };
        this.statisticalLogger.sendPredToProcess(kwargs);

        const processes = this.statisticalLogger.taskInfo.processes;
        this.makeProcessDetails(processes);
        const resultant = processes.reduce((res, p) => {
          const { subStepsTimes } = p;
          const { [this.taskStepsCount]: background, ...rest } = subStepsTimes;
          // this.backgroundTime += background;
          this.backgroundTime = background;
          return { ...res, ...rest };
        }, {});
        this.stepsTime = resultant;
      }
    },

    makeProcessDetails(processes) {
      this.processDetails['noOfProcesses'] = processes.length;
      for (let i = 0; i < processes.length; i++) {
        this.processDetails[i] = [
          processes[i].requiredStepsForStart[0],
          processes[i].requiredStepsForEnd.slice(-1)[0],
        ];
      }
    },

    setCycleTime(input) {
      this.cycleTime = { ...this.cycleTime, ...input };
    },

    setBackgroundTime(input) {
      this.backgroundTimeNew = input;
    },

    onCycleStart(currentProcess, cycleCount) {
      this.currentCycle = cycleCount;
    },

    onCycleEnd(currentProcess, processes) {
      // console.log('cycle end', {id:currentProcess.id,
      //   missedSteps: currentProcess.getMissedSteps(),
      //   processes,
      // });
      if (
        currentProcess
          .getMissedSteps()
          .map((value) => this.substepList[value]?.name).length > 0
      ) {
        let temp = currentProcess
          .getMissedSteps()
          .map((value) => this.substepList[value]?.name);
        this.missedStepsBuffer = [...this.missedStepsBuffer, ...temp];
      }
      let processNumber = currentProcess.id;
      this.cycleTime = { ...this.cycleTime, [processNumber]: '0.00' };
      if (currentProcess.id == processes[processes.length - 1].id) {
        this.missedSteps = [];
        this.missedSteps.push(...this.missedStepsBuffer);
        this.missedStepsBuffer = [];
        this.backgroundTimeNew = 0;
      }
    },

    async onLoad() {
      this.statisticalLogger = new StatisticalLogger(
        this.onCycleEnd,
        this.onCycleStart,
        null,
        null,
        null,
        this.setCycleTime,
        this.setBackgroundTime
      );
      const kwargs = {
        platform: 'cloud',
        bucket: `${this.organization}-training`,
        allTasks: this,
      };
      await this.statisticalLogger.setupVars(kwargs);
      this.currentDate = new Date();
    },

    onPlay() {
      const self = this;
      this.isVideoPlaying = true;
      if (!self.isPredictionFileNotExist) {
        this.interval = setInterval(
          self.updateFrames,
          1000 / Number(this.video?.fps || 30) / 2
        );
      }
      // this.stepsTime = {};
    },

    onPause() {
      this.isVideoPlaying = false;
      clearInterval(this.interval);
    },

    onEnd() {
      this.detectedSteps = {};
      this.currentCycle = 0;
      this.isVideoPlaying = false;
      clearInterval(this.interval);
    },

    updateFrames() {
      try {
        const num =
          this.$refs.video?.currentTime.toFixed(5) * (this.video?.fps || 30);
        this.frame = Math.floor(num);
        // console.log({ 'updateFrames-Current': Math.floor(num) });
        // const stepsObj = this.filePreds[this.frame];
        // this.currentDate.setSeconds(
        //   this.currentDate.getSeconds() + this.timeMultiplier * (40 / 1000)
        // );
        // if (stepsObj) {
        //   const kwargs = {
        //     platform: 'cloud',
        //     pred: stepsObj.pred,
        //     time: this.currentDate,
        //   };
        //   // console.log(kwargs);
        //   // console.log(this.frame);
        //   this.statisticalLogger.sendPredToProcess(kwargs);
        //   // console.log('Proc', this.statisticalLogger.taskInfo.processes);
        // }
        // this.timeMultiplier += 1;
        // this.updateMissedSteps();
      } catch (error) {
        console.log('update frame error', error);
        this.resetState();
      }
    },

    updateMissedSteps() {
      if (this.missedStepsList.length > 0) {
        const frameDiff =
          this.framesToShowMissedStepsOnList[this.indexOfCurrentMissedStep] -
          this.frame;
        if (Math.abs(frameDiff) < 15) {
          const missedStepsIndex = this.missedStepsList[
            this.indexOfCurrentMissedStep
          ];
          this.indexOfCurrentMissedStep += 1;
          this.missedSteps = missedStepsIndex
            .filter(
              (el) =>
                !this.negativeSteps.includes(el) &&
                !this.optionalSteps.includes(el)
            )
            .map((el) => this.substepList[el]?.name);
        }
      }
    },

    updateCycle() {
      const cyclesInterval = this.framesToShowMissedStepsOnList;
      for (let [index, value] of cyclesInterval.entries()) {
        if (
          value + 30 >= this.frame &&
          (this.frame >= this.firstFrame || this.isCycleStarted)
        ) {
          this.isCycleStarted = true;
          this.currentCycle = index + 1;
          break;
        }
      }
    },

    resetStepsTime() {
      this.stepsTime = {};
    },

    resetState() {
      this.frame = 0;
      this.currentStepList = [];
      this.videoUrl = '';
      this.debugPrediction = { 0: '' };
      this.predictions = {};
      this.currentCycle = 0;
      this.detectedSteps = {};
      // this.stepsTime = {};
      this.isVideoPlaying = false;
      clearInterval(this.interval);
    },

    async updatePredictions() {
      const { per_frame_prediction_file_url } = this.video;

      try {
        this.isDownloadingPrediction = true;
        const { data } = await axios.get(per_frame_prediction_file_url, {
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            this.predictionDownloadingProgress = percentCompleted;
          },
        });

        const { preds } = data;

        if (Object.keys(preds).length === 0) return;
        this.setPredictions(preds);
        this.filePreds = preds;
        this.firstFrame = Number(Object.keys(preds)[0]);
        this.framesToShowMissedStepsOnList = this.getFramesToShowMissedStepsOn(
          data
        );
        this.missedStepsList = this.getMissedStepsList(data);
      } catch (error) {
        console.log(error);
        this.isPredictionFileNotExist = true;
        return;
      } finally {
        this.isDownloadingPrediction = false;
        this.predictionDownloadingProgress = 0;
      }
    },

    getPredictions(predictionsUrls) {
      return new Promise(async (resolve, _) => {
        var responses = [];
        for (const url of predictionsUrls) {
          try {
            const { data } = await axios.get(url);
            responses.push(data);
          } catch (error) {
            responses.push(undefined);
          }
        }
        resolve(responses);
      });
    },

    getFramesToShowMissedStepsOn(data) {
      const { frames_to_show_missed_steps_on } = data;
      if (!this.isMultiProcessTask) return frames_to_show_missed_steps_on;
      const arr = [];
      frames_to_show_missed_steps_on.forEach((frame, index) => {
        if (index % 2 !== 0) {
          arr.push(frame);
        }
      });
      return arr;
    },

    getMissedStepsList(data) {
      const { lists_of_misssed_steps } = data;
      if (!this.isMultiProcessTask) return lists_of_misssed_steps;
      const arr = [];
      for (let i = 0; i < lists_of_misssed_steps.length; i += 2) {
        const tempArr = [...lists_of_misssed_steps[i]];
        if (lists_of_misssed_steps[i + 1])
          tempArr.push(...lists_of_misssed_steps[i + 1]);
        tempArr.sort((a, b) => a - b);
        arr.push(tempArr);
      }

      return arr;
    },

    setPredictions(preds) {
      let tempPreds = {};
      if (Array.isArray(Object.values(preds)[0])) {
        this.predictions = preds;
        return;
      }
      Object.entries(preds).forEach(([key, value]) => {
        tempPreds[key] = value.pred;
      });
      this.predictions = tempPreds;
    },

    updateTime() {
      if (!this.filePreds) return;
      if (Array.isArray(Object.values(this.filePreds)[0])) return;
      const tempStepsTime = { ...this.stepsTime };
      const stepsObj = this.filePreds[this.frame];
      if (!stepsObj) return;

      const stepIndex = stepsObj.pred;
      const isMultiStep = stepIndex.length > 1;
      let diff = 0;

      stepIndex.map((index) => {
        if (!stepsObj.times) return;

        if (index === this.taskStepsCount) {
          this.backgroundTime = stepsObj.times;
        } else {
          diff = stepsObj.times - this.prevTime;
          // diff = diff > 0 ? diff : 0;
        }

        if (isMultiStep) {
          if (tempStepsTime[index]) {
            if (diff > 0) {
              tempStepsTime[index] += diff;
            } else if (tempStepsTime[index] > stepsObj.times) {
              tempStepsTime[index] += stepsObj.times;
            } else tempStepsTime[index] = stepsObj.times;
          } else {
            tempStepsTime[index] = stepsObj.times;
          }
        } else {
          tempStepsTime[index] = stepsObj.times;
        }
      });

      this.stepsTime = tempStepsTime;
      if (stepIndex[0] !== this.taskStepsCount) {
        this.prevTime = stepsObj.times;
      }
    },
  },
};
</script>
<style scoped></style>
