//  ================================ Define Procedure =========================================
export const mainProcessSteps = [
  {
    target: '#taskName',
    header: {
      title: 'Task creation',
    },
    content: `Enter the task name you want to create.`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: '#processButton',
    header: {
      title: 'Task creation',
    },
    content: `Click <strong style="font-weight:600;">Add Process</strong> button to add a process.`,
  },
  {
    target: '#processName',
    header: {
      title: 'Task creation',
    },
    content: `Enter the process name.`,
  },
  //===========================
  {
    target: '#stepButton',
    header: {
      title: 'Task creation',
    },
    content: `Click <strong style="font-weight:600;">Add Step</strong> button to add step.`,
  },
  {
    target: '#stepName',
    header: {
      title: 'Task creation',
    },
    content: `Click here and enter a step name then press enter.`,
  },
  {
    target: '#substepButton',
    header: {
      title: 'Task creation',
    },
    content: `Click on <strong style="font-weight:600;"><i class="bi bi-plus-circle"></i></strong> button to add a sub-step.`,
  },
  {
    target: '#substepName',
    header: {
      title: 'Task creation',
    },
    content: `Click here and enter a sub-step name then press enter.`,
  },
  {
    target: '#substepButton',
    header: {
      title: 'Task creation',
    },
    content: `If needed, add multiple sub-steps.`,
  },
  {
    target: '#stepButton',
    header: {
      title: 'Task creation',
    },
    content: `If needed, add multiple steps by clicking <strong style="font-weight:600;">Add Step</strong> button.`,
  },
  {
    target: '#processButton',
    header: {
      title: 'Task creation',
    },
    content: `If needed, add multiple processes by clicking <strong style="font-weight:600;">Add Process</strong> button.`,
  },
  {
    target: '#addTaskButton',
    header: {
      title: 'Task creation',
    },
    content: `Click <strong style="font-weight:600;">Add Task</strong> button to create a task.`,
  },
  {
    target: '#tasksDropdown',
    header: {
      title: 'Task Details',
    },
    content: `Select the task you created to see the task details.`,
  },
];

//====================== Task details
export const taskDetailsSteps = [
  {
    target: '#taskEditButton',
    header: {
      title: 'Task Update',
    },
    content: `Also, You can update the task by clicking this button and follow the same process as task creation.
    <br>Note: You can not edit task name but only details.`,
  },
  {
    target: '#taskDeleteButton',
    header: {
      title: 'Task Delete',
    },
    content: `You can delete the task if no videos are uploaded for this task.`,
  },
  {
    target: '#translationButton',
    header: {
      title: 'Translation',
    },
    content: `You can add translated version of task details by clicking this button.`,
  },
  {
    target: '#translationEditButton',
    header: {
      title: 'Translation',
    },
    content: `You can edit translation by clicking this button.`,
  },
  {
    target: '#translationDeleteButton',
    header: {
      title: 'Translation',
    },
    content: `You can delete translation by clicking this button.`,
  },
  {
    target: '#translationName',
    header: {
      title: 'Translation',
    },
    content: `Once translation is created, You can see the translated 
            version of the task details by clicking this button.`,
  },
  //================================
  // {
  //   target: '#form-wrapper',
  //   header: {
  //     title: 'Task Update',
  //   },
  //   content: `You can update the steps and sub-steps but you can't update the task name.`,
  // },
  // {
  //   target: '#v-step-7',
  //   header: {
  //     title: 'Task Update',
  //   },
  //   content: `By clicking on <strong style="font-weight:600;">Update Task</strong> button you can update task
  //             with new steps or sub-steps but all your labelling files will be deleted.`,
  // },
  // {
  //   target: '#language-name',
  //   header: {
  //     title: 'Translation',
  //   },
  //   content: `You can see the translation language if you have added before.`,
  // },
  // {
  //   target: '#add-translation',
  //   header: {
  //     title: 'Translation',
  //   },
  //   content: `Or you can add new translation by clicking <strong style="font-weight:600;">New</strong>.`,
  // },
  // {
  //   target: '#form-wrapper',
  //   header: {
  //     title: 'Add Translation',
  //   },
  //   content: `Here you can specify language name and translated text against taskname, steps and sub-steps and
  //             then press <strong style="font-weight:600;">Add Translation</strong> button.`,
  // },
  // {
  //   target: '#edit-translation',
  //   header: {
  //     title: 'Update Translation',
  //   },
  //   content: `You can edit the translation by clicking <strong style="font-weight:600;">Edit</strong> button.`,
  // },
  // {
  //   target: '#delete-translation',
  //   header: {
  //     title: 'Delete Translation',
  //   },
  //   content: `Or You can delete the translation by clicking <strong style="font-weight:600;">Delete</strong> button.`,
  // },
  // {
  //   target: '#v-step-10',
  //   header: {
  //     title: 'Next Menu Item',
  //   },
  //   content: `Press <strong style="font-weight:600;">Next Step: Import Data</strong>.`,
  // },
];

// export const mainProcessSteps = [
//   {
//     target: "#v-step-0",
//     header: {
//       title: "Task creation",
//     },
//     content: `There are <strong style="font-weight:600;">two</strong> ways you can create a task.`,
//     params: {
//       enableScrolling: false,
//     },
//   },
//   {
//     target: "#v-step-1",
//     header: {
//       title: "1. By Uploading",
//     },
//     content: `Drag and drop <strong style="font-weight:600;">task.txt</strong> file from your machine.`,
//   },
//   {
//     target: "#v-step-2",
//     header: {
//       title: "1. By Uploading",
//     },
//     content: `Click on the <strong style="font-weight:600;">Upload File</strong> button.`,
//   },
//   {
//     target: "#v-step-3",
//     header: {
//       title: "1. By Uploading",
//     },
//     content: `Click on the <strong style="font-weight:600;">Add Task</strong> button.`,
//   },
//   {
//     target: "#v-step-4",
//     header: {
//       title: "1. By Uploading",
//     },
//     content: `Task will be created and you can see the task details over here.`,
//   },
//   {
//     target: "#v-step-5",
//     header: {
//       title: "2. By Using Portal",
//     },
//     content: `Specify task name.`,
//   },
//   {
//     target: "#v-step-6",
//     header: {
//       title: "2. By Using Portal",
//     },
//     content: `Specify step name (Cycle start, Assembly, QA, Cycle end etc.)`,
//   },
//   {
//     target: "#v-step-7",
//     header: {
//       title: "2. By Using Portal",
//     },
//     content: `Specify substep name (Take screwdriver, tighten bolt #1, etc.).
//     Press <strong style="font-weight:600;">Add</strong> button. Repeat this step multiple times if needed.`,
//   },
//   {
//     target: "#v-step-8",
//     header: {
//       title: "2. By Using Portal",
//     },
//     content: `Once substeps are added, press the <strong style="font-weight:600;">Add</strong> button for step.`,
//   },
//   {
//     target: "#v-step-9",
//     header: {
//       title: "2. By Using Portal",
//     },
//     content: `If needed, add multiple steps with substeps.`,
//   },
//   {
//     target: "#v-step-3",
//     header: {
//       title: "2. By Using Portal",
//     },
//     content: `Once the process is entered in full, press <strong style="font-weight:600;">Add Task</strong> button.`,
//   },
//   {
//     target: "#v-step-4",
//     header: {
//       title: "2. By Using Portal",
//     },
//     content: `Task will be created and you can see the task details over here.`,
//   },
//   {
//     target: "#v-step-10",
//     header: {
//       title: "Next Menu Item",
//     },
//     content: `Press <strong style="font-weight:600;">Next Step: Import Data</strong>.`,
//   },
// ];

//  ================================ Import Data ============================================
export const mainImportSteps = [
  {
    target: '#v-step-11',
    header: {
      title: 'Import Data',
    },
    content: `Make sure to select the task you have created previously. And press <strong style="font-weight:600;">Upload / Record Video</strong>`,
    params: {
      enableScrolling: true,
    },
  },
  {
    target: '#v-upload-button',
    header: {
      title: 'Import Data',
    },
    content: `Press <strong style="font-weight:600;">Upload / Record Video</strong> to open a dialog`,
    params: {
      enableScrolling: true,
    },
  },
  {
    target: '#v-step-12',
    header: {
      title: 'Import Data',
    },
    content: `You can record the video by pressing <strong style="font-weight:600;">Swith To Record Video</strong> button`,
    params: {
      enableScrolling: true,
    },
  },
  {
    target: '#v-step-13',
    header: {
      title: 'Import Data',
    },
    content: `Or you can drop mp4 files here and press <strong style="font-weight:600;">Upload Video</strong> button to upload.`,
    params: {
      enableScrolling: true,
    },
  },
  {
    target: '#v-step-14',
    header: {
      title: 'Import Data',
    },
    content: `If you have uploaded the video(s) before, 
        you can perform several actions on them such as 
        <strong style="font-weight:600;">Annotate</strong>, 
        <strong style="font-weight:600;">Trim</strong>, 
        <strong style="font-weight:600;">Protect</strong>,
        <strong style="font-weight:600;">Download</strong>, and
        <strong style="font-weight:600;">Delete</strong>.`,
    params: {
      enableScrolling: true,
    },
  },
  {
    target: '#v-step-15',
    header: {
      title: 'Import Data',
    },
    content: `After labelling a video you can see the labelled percentage of that video.`,
    params: {
      enableScrolling: true,
    },
  },
  {
    target: '#v-step-10',
    header: {
      title: 'Next Menu Item',
    },
    content: `Once you’ve labelled all videos, Press <strong style="font-weight:600;">Next Step: Train a model</strong>.`,
    params: {
      enableScrolling: true,
    },
  },
];
// export const mainImportSteps = [
//   {
//     target: '#v-step-11',
//     header: {
//       title: 'Import Data',
//     },
//     content: `There are <strong style="font-weight:600;">two</strong> ways you can upload & label the videos. One is By Uploading files.`,
//     params: {
//       enableScrolling: true,
//     },
//   },
//   {
//     target: '#v-step-12',
//     header: {
//       title: '1. By Uploading',
//     },
//     content: `Make sure the <strong style="font-weight:600;">task</strong> created previously is selected.`,
//   },
//   {
//     target: '#v-step-13',
//     header: {
//       title: '1. By Uploading',
//     },
//     content: `Drag and drop .mp4 video files associated with the task selected from
//         <strong style="font-weight:600;">Tasks/<Your task>/Videos</strong> folder.`,
//   },
//   {
//     target: '#v-step-14',
//     header: {
//       title: '1. By Uploading',
//     },
//     content: `Click on  <strong style="font-weight:600;">Upload video</strong> button and wait until videos will be uploaded.`,
//   },
//   {
//     target: '#v-step-15',
//     header: {
//       title: '1. By Uploading',
//     },
//     content: `Drag and drop corresponding metadata files with labels from
//         <strong style="font-weight:600;">Tasks/<Your task>Meta</strong> folder`,
//   },
//   {
//     target: '#v-step-16',
//     header: {
//       title: '1. By Uploading',
//     },
//     content: `Click on  <strong style="font-weight:600;">Upload Label</strong> button and wait until labels will be uploaded.`,
//   },
//   {
//     target: '#v-step-11',
//     header: {
//       title: 'Import Data',
//     },
//     content: `Alternate way is by using portal.`,
//     params: {
//       enableScrolling: false,
//     },
//   },
//   {
//     target: '#v-step-17',
//     header: {
//       title: '2. By Using Portal',
//     },
//     content: `Press <strong style="font-weight:600;">Switch To Upload Video</strong> button.`,
//   },
//   {
//     target: '#v-step-18',
//     header: {
//       title: '2. By Using Portal',
//     },
//     content: `Press <strong style="font-weight:600;">Camera</strong> icon in the center of the recorder.`,
//   },
//   {
//     target: '#v-step-18',
//     header: {
//       title: '2. By Using Portal',
//     },
//     content: `Start recording by pressing the bottom left circle on recorder and
//         capture an assembly cycle up to 10 minutes long.`,
//   },
//   {
//     target: '#v-step-19',
//     header: {
//       title: '2. By Using Portal',
//     },
//     content: `Press <strong style="font-weight:600;">Submit</strong> to upload video to a server.
//         Once the video is uploaded you’ll see it on the right table.`,
//   },
//   {
//     target: '#v-step-18',
//     header: {
//       title: '2. By Using Portal',
//     },
//     content: `Repeat if you need to record multiple videos.`,
//   },
//   {
//     target: '#v-step-21',
//     header: {
//       title: '2. By Using Portal',
//     },
//     content: `After Recording the videos, You'll see <strong style="font-weight:600;">Annotate</strong>
//         button in video listing on right side.`,
//     params: {
//       enableScrolling: false,
//     },
//   },
//   {
//     target: '#v-step-22',
//     header: {
//       title: '2. By Using Portal',
//     },
//     content: `Press <strong style="font-weight:600;">Annotate</strong> button and you'll see a dialog.`,
//   },
//   {
//     target: '#trim-button',
//     header: {
//       title: '2. By Using Portal',
//     },
//     content: `Press <strong style="font-weight:600;">Trim</strong> button to trim a longer video.`,
//   },
//   {
//     target: '#v-step-23',
//     header: {
//       title: '2. By Using Portal',
//     },
//     content: `You can label a video after reading the instruction or by watching this short
//         <a href="https://drive.google.com/file/d/1lWaGO2ANuUnbMS0ZSh4XRLsIE11wPPms/view" target="_blank">video</a>, then
//         press <strong style="font-weight:600;">Save</strong> button and you'll see the labelled % in video listing.`,
//   },
//   {
//     target: '#v-step-10',
//     header: {
//       title: 'Next Menu Item',
//     },
//     content: `Once you’ve labelled all videos, Press <strong style="font-weight:600;">Next Step: Train a model</strong>.`,
//   },
// ];

//  ================================ Train Model ============================================

export const trainModelSteps = [
  {
    target: '#v-step-24',
    header: {
      title: 'Train Model',
    },
    content: `Make sure the <strong style="font-weight:600;">task</strong> is selected.`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: '#v-step-25',
    header: {
      title: 'Train Model',
    },
    content: `Press <strong style="font-weight:600;">Start training</strong> button. Training process will take several hours.`,
  },
  {
    target: '#v-step-10',
    header: {
      title: 'Train Model',
    },
    content: `Once training is done, click <strong style="font-weight:600;">Next Step: Validation</strong>.`,
  },
];

//  ================================ Validation ============================================

export const validationSteps = [
  {
    target: '#v-step-26',
    header: {
      title: 'Validation',
    },
    content: `If you have trained task then Make sure the <strong style="font-weight:600;">task</strong> is selected.`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: '#v-step-27',
    header: {
      title: 'Validation',
    },
    content: `By pressing <strong style="font-weight:600;">Upload Video</strong> button
                a dialog will appear and you can drop or select multiple mp4 files to upload.`,
  },
  {
    target: '#v-step-28',
    header: {
      title: 'Validation',
    },
    content: `Once files are upload, you can see their status here.
    If the status is <strong style="font-weight:600;">failed</strong> you can 
    restart the process again by pressing the <strong style="font-weight:600;">retry</strong> button.
    Moreover, if the video is successfully processed, You will 
    see the <strong style="font-weight:600;">finished</strong> status.`,
  },
  {
    target: '#v-step-29',
    header: {
      title: 'Validation',
    },
    content: `You can see the validation by pressing the 
    <strong style="font-weight:600;">Play</strong> button.
    `,
  },
  {
    target: '#v-step-30',
    header: {
      title: 'Validation',
    },
    content: `By pressing the <strong style="font-weight:600;">Telemetry</strong> button,
    You will be redirected to Analytics page where you can see the anaylytics.`,
  },
  {
    target: '#v-step-31',
    header: {
      title: 'Validation',
    },
    content: `By pressing the <strong style="font-weight:600;">Trace</strong> button,
    You will be redirected to Trace page where you can see the trace.`,
  },
];
