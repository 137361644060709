class UserInfoInterface {
  constructor() {
    if (this.constructor == UserInfoInterface) {
      throw new Error("Abstract classes can't be instantiated.");
    }
    this.bucket = null;
    this.key = null;
    this.orgId = null;
    this.deviceId = null;
  }

  initialize() {
    throw new Error(
      'Abstract method. Method initialize() needs to be implemented'
    );
  }
}

export class UserInfoCloud extends UserInfoInterface {
  constructor(kwargs) {
    super();
    this.initialize(kwargs);
  }

  initialize(kwargs) {
    this.bucket = kwargs.bucket;
    this.key = kwargs.key;
    this.orgId = kwargs.id;
    this.deviceId = kwargs.deviceId;
  }
}
