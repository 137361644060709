<template>
  <a-card
    size="small"
    hoverable
    class="h-100 d-flex flex-column"
    :body-style="{ height: '1px', flex: '1 1 auto', overflowY: 'auto' }"
  >
    <template #title>
      <a-descriptions :title="null">
        <a-descriptions-item span="3" class="p-0" label="Cycle">
          {{ cycle }}
        </a-descriptions-item>
        <a-descriptions-item span="3" class="p-0" label="Cycle time">
          {{ cycleTime.toFixed(2) }}s
        </a-descriptions-item>

        <a-descriptions-item span="3" class="p-0" label="Background time">
          {{ backgroundTime.toFixed(2) }}s
        </a-descriptions-item>
      </a-descriptions>
    </template>
    <template #extra>
      <a-tag v-if="isPredictionFileNotExist" color="error">
        <template #icon>
          <stop-outlined />
        </template>
        No Predictions
      </a-tag>
    </template>

    <template v-if="propagatedSteps.length" #actions>
      <a-space
        v-for="(color, index) in stepsLegends"
        :key="index"
        :size="5"
        class="d-flex justify-content-center align-content-center steps-legends"
      >
        <span
          :class="`list-group-item  ${color.background} steps-legend-color`"
        />
        <span class="text-dark steps-legend-label">
          {{ color.label }}
        </span>
      </a-space>
    </template>

    <a-skeleton
      :loading="isTaskDetailsLoading"
      active
      :paragraph="{ rows: 13 }"
    >
      <ul id="inference-md-steps-list" class="list-group">
        <li
          v-for="(step, index) in stepsList"
          :id="'inference-md-s  tep-li-' + index"
          :key="index"
          class="list-group-item h-auto"
          :class="{
            'cursor-default': missedStepsList.includes(step.name),
            'current-step': currentStepList.includes(step.substepIndex),
            'list-group-item-primary font-weight-bold mt-1':
              step.type === 'step',
            'list-group-item-warning': currentStepList.includes(
              step.substepIndex
            ),
            'list-group-item-success':
              stepsTime[step.substepIndex] &&
              !currentStepList.includes(step.substepIndex) &&
              !propagatedSteps.includes(step.substepIndex) &&
              !missedStepsList.includes(step.name),
            'list-group-item-secondary': propagatedSteps.includes(
              step.substepIndex
            ),
          }"
          @click="$emit('handleClickSubStep', step.substepIndex)"
        >
          <span
            :id="'infer-step-li-' + index + '-name'"
            :class="{ 'ml-2': step.type === 'sub-step' }"
            class="pr-2"
          >
            {{ stepsTranslationMapping[step.name] || step.name }}
          </span>

          <span
            v-if="stepsTime[step.substepIndex] !== undefined"
            :id="'infer-step-li-' + index + '-substep-time'"
            class="ml-auto"
            :class="{
              'mr-2':
                negativeStepsList.includes(step.substepIndex) ||
                optionalStepsList.includes(step.substepIndex),
            }"
          >
            ({{ getTime(step.substepIndex) }})
          </span>
          <span
            v-if="negativeStepsList.includes(step.substepIndex)"
            :id="'infer-step-li-' + index + '-ve-step'"
            class="negative-step"
          >
            Negative step
          </span>

          <span
            v-if="optionalStepsList.includes(step.substepIndex)"
            :id="'infer-step-li-' + index + '-ve-step'"
            class="optional-step"
          >
            Optional step
          </span>
        </li>
      </ul>
    </a-skeleton>
  </a-card>
</template>
<script>
import { StopOutlined } from '@ant-design/icons-vue';
import { mapGetters } from 'vuex';

export default {
  components: { StopOutlined },
  props: {
    stepsList: { type: Array, default: () => [] },
    currentStepList: { type: Array, default: () => [] },
    cycle: {},
    cycleTime: { 0: 0 },
    backgroundTime: {},
    detectedSteps: {},
    missedStepsList: { default: [] },
    negativeStepsList: { default: [] },
    optionalStepsList: { default: [] },
    stepsTime: {},
    isTaskDetailsLoading: {},
    isPredictionFileNotExist: {},
    stepsTranslationMapping: { default: {} },
    processDetails: {},
    isValidation: { default: false },
    propagatedSteps: { default: [] },
  },

  emits: ['handleClickSubStep'],

  data() {
    return {
      stepsLegends: [
        { label: 'Completed Steps', background: 'list-group-item-success' },
        { label: 'Current Step', background: 'list-group-item-warning' },
        { label: 'Missed Steps', background: 'list-group-item-danger' },
        { label: 'Propagated Steps', background: 'list-group-item-secondary' },
      ],
    };
  },

  computed: {
    ...mapGetters(['taskProcesses']),
  },

  methods: {
    getTime(stepIndex) {
      return this.stepsTime[stepIndex]
        ? Number(this.stepsTime[stepIndex])?.toFixed(2) + ' s'
        : '0.00';
    },
  },
};
</script>
<style scoped>
.heading {
  margin-bottom: 0.5em;
}
.list-container {
  overflow-y: auto;
  height: 74vh;
}
.current-step {
  font-weight: bold;
  font-size: 0.9em !important;
}
.list-group-item {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 0.4em;
  font-size: 0.73em;
}
.negative-step {
  color: red;
}

.optional-step {
  color: blue;
}
.cursor-default {
  cursor: default;
}

.steps-legend-color {
  height: 15px;
  width: 15px;
  border-radius: 2px;
}

.steps-legends > .ant-space-item > .steps-legend-label {
  display: flex;
  font-size: 0.73em;
}
</style>
