export class TimeBreakPoints {
  static defaultStartTime = -2;
}

export class Process {
  constructor(
    name = 'default',
    subSteps = [],
    id = 0,
    minimumSubStepTimes = new Set(),
    negativeSteps = [],
    cycleCalculationParams = {}
  ) {
    subSteps = subSteps ?? [];
    this.id = id;
    this.processName = name;
    this.subSteps = subSteps;
    this.backgroundSubStep = this.subSteps.slice(-1);
    this.cycleStart = false;
    this.subStepCount = this.subSteps.length;

    // this.allMinimumSubstepTimes = this.subSteps.map(
    //   (value) => minimumSubStepTimes[value] * 0.4
    // );

    this.allMinimumSubstepTimes = this.subSteps.reduce((res, value) => {
      res[value] = minimumSubStepTimes[value] * 0.4;
      return res;
    }, {});

    this.subStepsTimes = {};
    this.subSteps.forEach(value => (this.subStepsTimes[value] = 0));

    this.completedSteps = [];
    this.totalCycleTime = 0;
    this.cycleIdentifier = '';
    this.startFrame = 0;
    this.endFrame = 0;
    this.totalInferenceTime = 0;
    this.previousPrediction = -1;
    this.startTime = TimeBreakPoints.defaultStartTime;

    this.cycleEndIdentifier = '';
    this.negativeSteps = negativeSteps;
    this.reasonForEnd = new Set();
    this.cycleCalculationParams = cycleCalculationParams;

    this.minPercentageOfStepsRequiredToEndCycle =
      this.cycleCalculationParams[
        'min_percentage_of_steps_required_to_end_cycle'
      ] ?? 0.75;
    try {
      this.tempRequiredStepsForEnd = this.cycleCalculationParams[
        'required_steps_for_end'
      ] ?? { id: this.subSteps.slice(-3, -1) };
      this.requiredStepsForEnd =
        Object.values(this.tempRequiredStepsForEnd[id]) ??
        this.subSteps.slice(-3, -1);
      this.tempRequiredStepsForStart = this.cycleCalculationParams[
        'required_steps_for_start'
      ] ?? { id: this.subSteps.slice(0, 2) };
      this.requiredStepsForStart =
        Object.values(this.tempRequiredStepsForStart[id]) ??
        this.subSteps.slice(0, 2);
      this.minimumCycleTimeMultiplier =
        this.cycleCalculationParams['minimum_cycle_time_multiplier'] ?? 0.4;
      this.maximumCycleTimeMultiplier =
        this.cycleCalculationParams['maximum_cycle_time_multiplier'] ?? 8.0;
    } catch (e) {
      this.requiredStepsForEnd = this.subSteps.slice(-3, -1);
      this.requiredStepsForStart = [0, 1];
      this.minimumCycleTimeMultiplier = 0.4;
      this.maximumCycleTimeMultiplier = 8.0;
      console.log('Exception raised while parsing cycle calculation params');
    }

    this.canRestartCycleOn = this.requiredStepsForStart;
    this.minimumSubStepTimes = this.subSteps
      .slice(0, -1)
      .map(
        (_, index) =>
          minimumSubStepTimes[index] * this.minimumCycleTimeMultiplier
      );
    this.minimumCycleTime = this.minimumSubStepTimes.reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    this.maximumCycleTime =
      Object.values(this.allMinimumSubstepTimes).reduce(
        (partialSum, a) => partialSum + a,
        0
      ) * this.maximumCycleTimeMultiplier;
  }

  resetValues() {
    this.completedSteps = [];
    this.cycleStart = false;
    this.totalInferenceTime = 0;
    this.previousPrediction = -1;
    this.startTime = TimeBreakPoints.defaultStartTime;
    this.totalCycleTime = 0;
    this.subSteps.forEach(value => (this.subStepsTimes[value] = 0));
    this.startFrame = 0;
    this.endFrame = 0;
    this.reasonForEnd = new Set();
  }

  getTotalCycleTime() {
    this.totalSecondsList = this.subStepsTimes.map(value =>
      value.totalSeconds()
    );
    return this.totalSecondsList.reduce((partialSum, a) => partialSum + a, 0);
  }

  isCurrentCycleTimeGreaterThanMaximumCycleTime() {
    if (
      this.totalCycleTime > this.maximumCycleTime &&
      this.completedSteps.length > 0
    ) {
      this.reasonForEnd.add('max time reached');
      return true;
    }
    return false;
  }

  addSubStepTime(timeDiff, prediction) {
    this.subStepsTimes[prediction] += timeDiff;
  }

  resetSubSteps() {
    console.log('reset substeps');
  }

  markStepAsCompleted(prediction) {
    if (
      this.isStepComplete(prediction) &&
      !this.completedSteps.includes(prediction) &&
      prediction != this.backgroundSubStep
    ) {
      this.completedSteps.push(prediction);
      return true;
    }
    return false;
  }

  isStepComplete(prediction) {
    return (
      this.subStepsTimes[prediction] >= this.allMinimumSubstepTimes[prediction]
    );
  }

  areAllStepsCompleted() {
    if (this.completedSteps.length == this.subStepCount - 1) {
      this.reasonForEnd.add('all steps complete');
      return true;
    }
    return false;
  }

  isMinimumPercentageToEndCycleReached() {
    if (
      this.completedSteps.length / this.subStepCount >=
      this.minPercentageOfStepsRequiredToEndCycle
    ) {
      this.reasonForEnd.add('is_minimum_percentage_to_end_cycle_reached');
      return true;
    }
    return false;
  }

  doesTotalCycleTimeExceedMinimumCycleTime() {
    if (this.totalCycleTime >= this.minimumCycleTime) {
      this.reasonForEnd.add('minimum cycle time achieved');
      return true;
    }
    return false;
  }

  lastNStepsDetected() {
    for (let step in this.requiredStepsForEnd) {
      if (this.completedSteps.includes(step) && this.lastStepDetected()) {
        this.reasonForEnd.add('last n steps detected');
        return true;
      }
    }
    return false;
  }

  lastStepDetected() {
    return this.completedSteps.includes(
      this.subSteps[this.subSteps.length - 2]
    );
  }

  areMinimalRequirementsForCycleEndMet() {
    return (
      this.isMinimumPercentageToEndCycleReached() &&
      this.doesTotalCycleTimeExceedMinimumCycleTime()
    );
  }

  canEndNormally() {
    return (
      this.areAllStepsCompleted() ||
      (this.lastNStepsDetected() &&
        this.areMinimalRequirementsForCycleEndMet()) ||
      this.isCurrentCycleTimeGreaterThanMaximumCycleTime()
    );
  }

  serialize() {
    return {
      cycleIdentifier: this.cycleIdentifier,
      processId: this.id,
      completedSteps: this.completedSteps,
      subStepsTimes: this.subStepsTimes,
      totalCycleTime: this.totalCycleTime,
      totalInferenceTime: this.totalInferenceTime,
      startFrame: this.startFrame,
      endFrame: this.endFrame,
      cycleEndIdentifier: this.cycleEndIdentifier,
      missedSteps: this.getMissedSteps(),
      reasonForEnd: this.reasonForEnd
    };
  }

  canEndAbnormally(prediction) {
    return (
      this.canRestartCycleOn.includes(prediction) &&
      (this.lastNStepsDetected() || this.isMinimumPercentageToEndCycleReached())
    );
  }

  getMissedSteps() {
    const differenceOne = new Set(
      [...this.subSteps.slice(0, -1)].filter(
        x => !new Set(this.completedSteps).has(x)
      )
    );
    const differenceTwo = new Set(
      [...differenceOne].filter(x => !new Set(this.negativeSteps).has(x))
    );
    return [...differenceTwo];
  }
}
